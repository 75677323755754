import { NgModule } from "@angular/core";
import { MediaServiceRegistry } from "@tytapp/media-playback";
import { HLSPlaybackService } from "./hls-playback";

@NgModule({
    providers: [
        HLSPlaybackService
    ]
})
export class TYTHLSModule {
    constructor(
        mediaServices: MediaServiceRegistry,
        hlsService: HLSPlaybackService,
    ) {
        mediaServices.register(hlsService);
    }
}