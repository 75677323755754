import { Component, HostBinding } from '@angular/core';
import { environment } from '@tytapp/environment';

@Component({
    selector: 'tyt-nav-logo',
    templateUrl: './nav-logo.component.html',
    styleUrls: ['./nav-logo.component.scss']
})
export class NavLogoComponent {
    @HostBinding('class.animate-in')
    animateIn = false;

    ngOnInit() {
        setTimeout(() => this.animateIn = true, 300);
    }
}