import { NgModule } from "@angular/core";
import { MediaServiceRegistry } from "@tytapp/media-playback";
import { CastRemotePlaybackService } from "./cast-remote-playback";

@NgModule({
    providers: [
        CastRemotePlaybackService
    ]
})
export class TYTCastRemoteModule {
    constructor(
        mediaServices: MediaServiceRegistry,
        castRemoteService: CastRemotePlaybackService
    ) {
        mediaServices.register(castRemoteService);
    }
}