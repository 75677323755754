import { ElementRef } from '@angular/core';
import { MediaService, PlaybackSession, AssetResolution, HTMLMediaPlaybackSession, PlaybackHints, MediaItem } from '@tytapp/media-playback';
import { Injectable } from '@angular/core';
import { ApiAvAsset } from '@tytapp/api';

const SUPPORTED_FILE_EXTENSIONS = ['wav', 'mp3', 'ogg', 'aac', 'm4a'];

/**
 * HLS playback service
 */
@Injectable()
export class HTMLAudioPlaybackService implements MediaService {

    constructor() {
    }

    get id() {
        return 'html-audio';
    }

    resolve(item: MediaItem): AssetResolution {
        let asset: ApiAvAsset = item.item.asset;
        let url = asset.url.replace(/\?.*/, '');

        if (!SUPPORTED_FILE_EXTENSIONS.find(x => url.toLowerCase().endsWith(`.${x}`)))
            return null;

        return {
            item,
            service: this,
            asset,
            data: {}
        };
    }

    /**
     * Play the given media resolution within the given root element.
     */
    async play(mediaResolution: AssetResolution, rootElement: ElementRef, hints?: PlaybackHints): Promise<PlaybackSession> {
        let assetUrl = mediaResolution.asset.url;
        let audioElement = document.createElement('audio');
        if (!hints)
            hints = {};
        let autoplay = hints.autoplay !== false;

        audioElement.controls = false;
        audioElement.autoplay = autoplay;
        audioElement.src = assetUrl;

        rootElement.nativeElement.appendChild(audioElement);

        return new HTMLMediaPlaybackSession(mediaResolution.item, this, audioElement, mediaResolution.asset, hints.startPosition || 0);
    }
}
