<div class="mobile-sidebar-bg" [class.active]="sidebarVisible"
(click)="hideSidebar()"></div>

<div id="mobile-menu-background" onclick="document.querySelector('nav#menu').classList.remove('active');
           document.querySelector('#mobile-menu-background').classList.remove('active');
           document.documentElement.classList.remove('menu-open');"></div>
<nav id="menu">
  <div class="site-wide-alerts">
    @for (alert of alerts; track alert) {
      <div class="site-wide-alert" [class.danger]="alert.type == 'danger'" [class.warning]="alert.type == 'warning'"
        [style.background-color]="alert.backgroundColor" [style.color]="alert.textColor">
        <!-- MESSAGE -->
        @if (!alert.url && alert.handler) {
          <a mat-button class="message" href="javascript:;" (click)="alert.handler()">
            <div class="message-contents"><mat-icon>{{alert.icon || 'campaign'}}</mat-icon>
            {{alert.message}}
          </div>
        </a>
      }
      @if (alert.url && !alert.internal) {
        <a mat-button class="message" target="_blank" [href]="alert.url">
          <div class="message-contents"><mat-icon>{{alert.icon || 'campaign'}}</mat-icon>
          {{alert.message}}
        </div>
      </a>
    }
    @if (alert.url && alert.internal) {
      <a mat-button class="message" [routerLink]="alert.url">
        <div class="message-contents"><mat-icon>{{alert.icon || 'campaign'}}</mat-icon>
        {{alert.message}}
      </div>
    </a>
  }
  @if (!alert.url && !alert.handler) {
    <a mat-button class="message">
      <div class="message-contents"><mat-icon>{{alert.icon || 'campaign'}}</mat-icon>
      {{alert.message}}
    </div>
  </a>
}
<!-- END MESSAGE -->
<a mat-button class="close-button" href="javascript:;" (click)="dismissAlert(alert)" aria-label="Close Alert">
  <div>
    <mat-icon>close</mat-icon>
  </div>
</a>
</div>
}
</div>
<div class="size-container">
  @if (environment.showDevTools && envMessage) {
    <span class="env"
      [style.background-color]="envHintBackgroundColor"
      [style.color]="envHintTextColor">
      {{envMessage}}
    </span>
  }
  <ul #menu class="menu-list primary">
    @for (item of primaryMenu; track item) {
      @if (shouldShowNav(item)) {
        <li [id]="'topnav_' + item.id">
          @if (item.external && !item?.subitems) {
            <a [ngClass]="classesForItem(item)" href="{{item.url}}" target="_blank">
              <span>{{item.label}}</span>
            </a>
          } @else {
            @if (!item?.subitems) {
              <a routerLinkActive="active" [routerLinkActiveOptions]="{exact: item.url === '/' }"
                 [ngClass]="classesForItem(item)" [routerLink]="item.url" (click)="activateItem(item, $event)">
                <span>{{item.label}}</span>
              </a>
            }
          }
          @if(item?.subitems) {
            <button class="dropdown" [matMenuTriggerFor]="subMenu">
              <mat-icon>arrow_drop_down</mat-icon>

              <span class="dropdown-label">
                {{item.label}}
              </span>

              <mat-menu #subMenu="matMenu">
                @for(subitem of item.subitems; track subitem) {
                  <a [routerLink]="subitem.url">
                    <span mat-menu-item>{{ subitem.label }}</span>
                  </a>
                }
              </mat-menu>
            </button>
          }
        </li>
      }
    }
    @if(isStaff && canEditNavItem) {
      <li>
        <a url="/nav/edit" class="edit" matTooltip="Edit navigation (staff only)">
          <mat-icon>settings</mat-icon>
        </a>
      </li>
    }
  </ul>

  <div class="mobile-menu">
    <a mat-icon-button [attr.aria-label]="sidebarVisible ? 'Navigation' : 'Close navigation'" href="javascript:;" (click)="toggleSidebar()">
      <mat-icon>{{ sidebarVisible ? 'close' : 'menu' }}</mat-icon>
    </a>

    <span id="mobilenav_join">
      <a [routerLink]="entitled ? '/settings/memberships' : '/join/membership'">
        <span>{{ entitled ? 'Plus' : hasMembership ? 'Renew' : 'Join' }}</span>
      </a>
    </span>
  </div>

  <div class="spacer"></div>
  <tyt-nav-logo></tyt-nav-logo>
  <div class="spacer"></div>

  <div id="account-mobile">
    @if (!user) {
      @if (entitled) {
        <a mat-button routerLink="/signup">Sign Up</a>
      } @else {
        <a mat-button routerLink="/login">Sign In</a>
      }
    }

    <tyt-basic-search [offset]="siteWideAlertOffset"></tyt-basic-search>

    @if (user) {
      <tyt-nav-avatar
        (activated)="toggleNotificationDrawer()"
        [class.active]="notificationsVisible"
        [image]="user?.profile?.avatar"
        [count]="notificationsCount"
      ></tyt-nav-avatar>
    }
  </div>

  <div id="account" class="only-desktop">
    <div>
      @if (casting) {
        <tyt-cast-button></tyt-cast-button>
      }
    </div>

    <ul class="menu-list">

      @for (item of secondaryMenu; track item) {
        @if (shouldShowNav(item)) {
          <li [id]="'accountmenu_' + item.id">
            @if (item?.subitems) {
              <button class="dropdown" [matMenuTriggerFor]="subMenu">
                <mat-icon class="arrow">arrow_drop_down</mat-icon>
                <span class="dropdown-label">
                  {{item.label}}
                </span>
                <mat-menu #subMenu="matMenu">
                  @for(subitem of item.subitems; track subitem) {
                    <span mat-menu-item>{{ subitem.label }}</span>
                  }
                </mat-menu>
              </button>
            } @else {
              @if (item.external) {
                <a
                  [ngClass]="classesForItem(item)"
                  href="{{item.url}}"
                  target="_blank"
                  (click)="activateItem(item, $event)"
                >
                  <span>{{item.label}}</span>
                </a>
              } @else {
                <a
                  routerLinkActive="active"
                  [routerLinkActiveOptions]="{exact: item.url === '/' }"
                  [ngClass]="classesForItem(item)"
                  [routerLink]="item.url"
                  (click)="activateItem(item, $event)"
                >
                  <span>{{item.label}}</span>
                </a>
              }
            }
          </li>
        }
      }

      @if (user) {
        @if (userDisplayName) {
          <li>
            <span class="username">
              Hi {{userDisplayName}}
            </span>
          </li>
        }
      }
      <li id="nav-item-search">
        <tyt-basic-search [offset]="siteWideAlertOffset"></tyt-basic-search>
      </li>
      @if (user) {
        <li>
          <span>
            <tyt-nav-avatar
              (activated)="toggleNotificationDrawer()"
              [class.active]="notificationsVisible"
              [image]="user?.profile?.avatar"
              [count]="notificationsCount"
            ></tyt-nav-avatar>
          </span>
        </li>
      }
    </ul>
  </div>
</div>
</nav>
<tyt-notifications-list></tyt-notifications-list>
@if (!sidebarHidden) {
  <div class="mobile-sidebar" [class.active]="sidebarVisible" [class.hidden]="sidebarHidden" [attr.aria-hidden]="!sidebarVisible" [style.margin-top]="siteWideAlertOffset">
    <ul #menu id="mobile-menu-list">
      @for (item of mobileMenu; track item) {
        @if (shouldShowNav(item)) {
          <li [id]="'mobilenav_' + item.id">
            @if (item.external) {
              <a
                mat-button
                [ngClass]="classesForItem(item)"
                href="{{item.url}}"
                target="_blank"
                (click)="activateItem(item, $event)"
                >
                <tyt-icon [icon]="item.icon" />
                <span>{{item.label}}</span>
              </a>
            }
            @if (!item.external) {
              <a
                mat-button
                routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: item.url === '/' }"
                [ngClass]="classesForItem(item)"
                [routerLink]="item.url"
                (click)="activateItem(item, $event)"
                >
                <tyt-icon [icon]="item.icon" />
                <span>{{item.label}}</span>
              </a>
            }
          </li>
        }
      }
      @if(isStaff && canEditNavItem) {
        <li>
          <a mat-button (click)="navigateToEditNav()">
            <mat-icon>settings</mat-icon>
            <span>Edit Navigation</span>
          </a>
        </li>
      }
    </ul>
  </div>
}
