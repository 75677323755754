<a aria-label="Home Page (TYT Logo)" id="logo" routerLink="/home">
  <svg aria-label="TYT Logo" class="tyt nav-logo" version="1.1" x="0px" y="0px" viewBox="0 0 279.1 198.2"
    style="enable-background:new 0 0 279.1 198.2;" xml:space="preserve" aria-label="TYT Logo">
    <style type="text/css">
      .nav-logo .letter {
        fill: #FFFFFF;
      }

      .nav-logo .shield {
        fill: #21232b;
      }
    </style>
    <path class="shield" d="M265,0h-251C6.3,0,0,6.3,0,14.1v126.2c0,6,3.8,11.3,9.4,13.3L134,197.4c3,1,6.2,1.1,9.2,0.1l126.4-42.6
      c5.7-1.9,9.6-7.3,9.6-13.3V14.1C279.1,6.3,272.8,0,265,0z" />
    <g>
      <path class="letter" d="M94.6,49.1l-6.5-19.6c-0.4-1.2-1.5-2-2.7-2H24.6c-1.8,0-3.2,1.7-2.8,3.5l4.4,19.6c0.3,1.3,1.5,2.3,2.8,2.3
        h17.3c1.6,0,2.9,1.3,2.9,2.9v84c0,1.3,0.9,2.5,2.3,2.8l21.1,4.7c1.8,0.4,3.5-1,3.5-2.8V55.8c0-1.6,1.3-2.9,2.9-2.9h12.9
        C93.8,52.9,95.2,50.9,94.6,49.1z" />
      <path class="letter" d="M184.5,49.1l6.5-19.6c0.4-1.2,1.5-2,2.7-2h60.7c1.8,0,3.2,1.7,2.8,3.5l-4.4,19.6c-0.3,1.3-1.5,2.3-2.8,2.3
        h-17.3c-1.6,0-2.9,1.3-2.9,2.9v84c0,1.3-0.9,2.5-2.3,2.8l-21.1,4.7c-1.8,0.4-3.5-1-3.5-2.8V55.8c0-1.6-1.3-2.9-2.9-2.9h-12.9
        C185.2,52.9,183.9,50.9,184.5,49.1z" />
      <path class="letter" d="M154.2,29.5l-14.7,45.4l-14.7-45.4c-0.4-1.2-1.5-2-2.7-2h-21.7c-2,0-3.4,2-2.7,3.8l26.4,75.3
        c0.1,0.3,0.2,0.6,0.2,0.9l0.3,51.8c0,1.3,0.9,2.5,2.2,2.8l12.2,3c0.5,0.1,0.9,0.1,1.4,0l12.2-3c1.3-0.3,2.2-1.5,2.2-2.8l0.3-51.8
        c0-0.3,0.1-0.6,0.2-0.9l26.4-75.3c0.7-1.9-0.7-3.8-2.7-3.8h-21.7C155.6,27.5,154.5,28.3,154.2,29.5z" />
    </g>
  </svg>
</a>