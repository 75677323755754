import { NgModule } from "@angular/core";
import { MediaServiceRegistry } from "@tytapp/media-playback";
import { HTMLAudioPlaybackService } from "./html-audio-playback";

@NgModule({
    providers: [
        HTMLAudioPlaybackService
    ]
})
export class TYTHTMLAudioModule {
    constructor(
        mediaServices: MediaServiceRegistry,
        mediaService: HTMLAudioPlaybackService
    ) {
        mediaServices.register(mediaService);
    }
}