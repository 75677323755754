import { ContentBlockType } from '@tytapp/content';
import { ContentViewBlock } from './content-view-block';

export * from './content-view-block';

export const CONTENT_VIEW_BLOCK_TYPE: ContentBlockType<ContentViewBlock> = {
    id: 'content-view',
    label: 'Layout',
    icon: 'dashboard',
    isLayout: true,
    template: {
        blocks: []
    },
    deferredEditorComponent: () => import('./content-view-block.edit').then(m => m.EditorComponent),
    deferredViewComponent: () => import('./content-view-block.view').then(m => m.ViewComponent)
};
