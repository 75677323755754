import { AssetResolution, PlaybackSession, PlaybackState } from '@tytapp/media-playback';
import { YouTubePlaybackService } from './youtube-playback';
import { YouTubePlayer } from './types';
import { NgZone } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { sleep } from '@tytapp/common';
import { ApiAvAsset } from '@tytapp/api';
import { isClientSide } from '@tytapp/environment-utils';

/**
 * YouTube playback session (used by YouTubePlaybackService)
 */
export class YouTubePlaybackSession implements PlaybackSession {

    constructor(
        service: YouTubePlaybackService,
        private mediaResolution: AssetResolution,
        element: HTMLElement,
        ytPlayer: YouTubePlayer,
        private zone: NgZone,
        private isLive: boolean
    ) {
        this._service = service;
        this._asset = mediaResolution.asset;
        this._resolution = mediaResolution;
        this._element = element;
        this._ytPlayer = ytPlayer;

        let firstUpdate = true;

        window['yt'] = ytPlayer;
        this.fullDuration = 0;
        let loadStamp = 0;

        this.supportedPlaybackSpeeds = ytPlayer.getAvailablePlaybackRates();

        this._positionInterval = setInterval(() => {

            if (this.isLive) {

                let newDuration = this.fullDuration;
                let position = this.position;

                if (loadStamp > 0)
                    newDuration += (Date.now() - loadStamp) / 1000.0;

                if (newDuration > 0 && position > 0 && loadStamp === 0) {
                    loadStamp = Date.now();
                    this.fullDuration = position;
                }

                if (position > this.fullDuration) {
                    this.fullDuration = position;
                    loadStamp = Date.now();
                }


                let reportedDuration = Math.min(this.dvrLimit, this.fullDuration);
                let unseekableOffset = this.fullDuration - reportedDuration;
                let reportedPosition = position - unseekableOffset;

                this._positionChanged.next(reportedPosition);

                if (this._length != reportedDuration) {
                    this._length = reportedDuration;
                    this._lengthChanged.next(this._length);
                }

                // if (this.isLive && newDuration > 0 && this.position > 0 && firstUpdate) {
                //     this.liveOffset = newDuration - this.position + (newDuration * 0.02);
                //     firstUpdate = false;
                // }
            } else {
                this._positionChanged.next(this.position);
                let newDuration = ytPlayer.getDuration();

                if (this._length != newDuration) {
                    this._length = newDuration;
                    this._lengthChanged.next(Math.min(newDuration, this._length));
                }
            }

            let newSpeed = ytPlayer.getPlaybackRate();
            if (this._speed != newSpeed) {
                this._speed = newSpeed;
                this._speedChanged.next(this.speed);
            }

        }, 1000);

        this._length = ytPlayer.getDuration();
        this._lengthChanged.next(this._length);
    }

    fullDuration: number = 0;
    supportedPlaybackSpeeds: number[];
    _speed: number;
    _speedChanged: BehaviorSubject<number> = new BehaviorSubject<number>(1);

    canBeWatchedExternally = true;
    watchExternalLabel = 'Watch on YouTube';

    watchExternally() {
        window.open(this.popOutURL, '_blank', 'noopener');
    }

    setSpeed(rate: number) {
        this.ytPlayer.setPlaybackRate(rate);
    }

    onPlaybackRateChange(ev) {
        if (this._speed != ev.data) {
            this._speed = ev.data;
            this._speedChanged.next(this._speed);
        }
    }

    get speedChanged(): Observable<number> {
        return this._speedChanged;
    }

    get speed() {
        return this.ytPlayer.getPlaybackRate();
    }

    get item() {
        return this.mediaResolution.item;
    }

    get dvrLimit() {
        return 60 * 60 * 4;
    }

    seek(positionInSeconds: number) {
        if (this.isLive) {
            let reportedDuration = Math.min(this.dvrLimit, this.fullDuration);
            let unseekableOffset = this.fullDuration - reportedDuration;

            this.ytPlayer.seekTo(unseekableOffset + positionInSeconds);
        } else {
            this.ytPlayer.seekTo(positionInSeconds);
        }

        this._onSeek.next(positionInSeconds);
    }

    liveOffset: number = 0;

    get popOutURL() {
        return this.mediaResolution.url;
    }

    get volume() {
        return this.ytPlayer.getVolume() / 100.0;
    }

    handlePlayStateChanged(state) {
        this.zone.run(() => {
            if (state == 0) {
                // Ended
                this.onEnd();
            } else if (state == 1) {
                this._playState = 'playing';
                this._playStateChanged.next('playing');
            } else if (state == 2) {
                this._playState = 'paused';
                this._playStateChanged.next('paused');
            } else if (state == 3) {
                this._playState = 'buffering';
                this._playStateChanged.next('buffering');
            }
        });
    }

    onEnd() {
        this._finished.next();
    }

    stop() {
        this.ytPlayer.stopVideo();
    }

    async destroy() {
        try {
            this.stop();
        } catch (e) {
            console.error(`YouTube: Caught error while trying to destroy video:`);
            console.error(e);
        }

        this._element.remove();
        clearInterval(this._positionInterval);
        await sleep(1);
    }

    private _positionInterval;
    private _service: YouTubePlaybackService;
    private _asset: ApiAvAsset;
    private _resolution: AssetResolution;
    private _element: HTMLElement;
    private _length: number;
    private _position: number;
    private _ytPlayer: YouTubePlayer;
    private _positionChanged: Subject<number> = new Subject<number>();
    private _lengthChanged: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    private _finished: Subject<void> = new Subject<void>();
    private _playStateChanged: Subject<PlaybackState> = new Subject<PlaybackState>();
    private _seekable: boolean = true;
    private _onSeek: Subject<number> = new Subject<number>();

    get onSeek(): Observable<number> {
        return this._onSeek;
    }

    get finished(): Observable<void> {
        return this._finished;
    }

    get playStateChanged(): Observable<PlaybackState> {
        return this._playStateChanged;
    }

    get seekable() {
        return this._seekable;
    }

    get hasChat() {
        return true;
    }

    get chatEmbed() {
        let origin = 'tytnetwork.com';
        if (isClientSide())
            origin = window.location.hostname;

        let chatUrl = `https://www.youtube.com/live_chat?v=${this._resolution.data.id}&is_popout=1&embed_domain=${origin}&app=desktop`;
        return `
            <iframe style="width:100%; height:100%; top: 0; left: 0; right: 0; bottom: 0; position: absolute; border: none;"
                src="${chatUrl}"></iframe>`
            ;
    }

    get length() { return this._length - this.liveOffset; }
    get position() { return this.ytPlayer.getCurrentTime(); }
    get service() { return this._service; }
    get element() { return { nativeElement: this._element }; }
    get asset() { return this._asset; }
    get positionChanged(): Observable<number> { return this._positionChanged; }
    get lengthChanged(): Observable<number> { return this._lengthChanged; }
    get playState() { return this._playState; }
    _playState: PlaybackState;

    private get ytPlayer() {
        return this._ytPlayer;
    }

    async pause() {
        try {
            await this.ytPlayer.pauseVideo();
        } catch (e) {

        }
    }

    async resume() {
        try {
            await this.ytPlayer.playVideo();
        } catch (e) {

        }
    }

    setVolume(level: number) {
        this.ytPlayer.setVolume(level * 100);
        this._volumeChanged.next(level);
    }

    private _volumeChanged: Subject<number> = new Subject<number>();
    get volumeChanged(): Observable<number> {
        return this._volumeChanged;
    }
}
