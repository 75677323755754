import { ElementRef } from '@angular/core';
import { MediaService, PlaybackSession, AssetResolution, HTMLMediaPlaybackSession, PlaybackHints, MediaItem } from '@tytapp/media-playback';
import { Injectable } from '@angular/core';
import { ApiAvAsset } from '@tytapp/api';

/**
 * HLS playback service
 */
@Injectable()
export class HTMLVideoPlaybackService implements MediaService {

    constructor() {
    }

    get id() {
        return 'html-video';
    }

    resolve(item: MediaItem): AssetResolution {
        let asset: ApiAvAsset = item.item.asset;
        let url = asset.url;

        if (asset.provider !== 'downloads' && !url.endsWith('.mp4'))
            return null;

        return {
            item,
            service: this,
            asset,
            data: {}
        };
    }

    /**
     * Play the given media resolution within the given root element.
     */
    async play(mediaResolution: AssetResolution, rootElement: ElementRef, hints?: PlaybackHints): Promise<PlaybackSession> {
        let assetUrl = mediaResolution.asset.url;
        let video = document.createElement('video');

        if (!hints)
            hints = {};

        let autoplay = hints.autoplay !== false;
        video.controls = false;
        video.autoplay = autoplay;
        video.playsInline = true;
        video.setAttribute('playsinline', 'true');

        if ('autoPictureInPicture' in video)
            (video as any).autoPictureInPicture = true;

        video.src = assetUrl;

        rootElement.nativeElement.appendChild(video);

        return new HTMLMediaPlaybackSession(mediaResolution.item, this, video, mediaResolution.asset, hints.startPosition || 0);
    }
}
