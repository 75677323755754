@if (enableSearch) {
  <button #searchToggle class="search-toggle" mat-icon-button class="unstyled" (click)="toggleExpanded()" aria-label="Search Popup Toggle" aria-expanded="false" attr.aria-controls="search-popup-form-{{unique}}">
    <mat-icon>search</mat-icon>
  </button>
  <form id="search-popup-form-{{unique}}" class="search-popup" method="get" action="/search/results" [style.--offset]="offset" [queryParamsReviser]="preFormatter" (formGetSubmitted)="analyticsHandler($event)">
    <div class="header">
      <div class="label">Search</div>
      <button type="button" mat-icon-button class="unstyled" aria-label="close" (click)="toggleExpanded()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <div class="field-wrapper">
      <mat-icon>search</mat-icon>
      <input type="search" name="q" class="field" placeholder="Enter Search" ngModel required>
    </div>
    @if (enableAdvancedSearch) {
      <a routerLink="/search" (click)="toggleExpanded()">
        <mat-icon style="vertical-align: bottom;">chevron_right</mat-icon>
        More Search Options
      </a>
    }
    <button type="submit" mat-button (click)="toggleExpanded()">
      Search
    </button>
  </form>
}
