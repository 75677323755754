import { Component } from '@angular/core';
import { environment } from '@tytapp/environment';

@Component({
    selector: 'tyt-site-footer',
    templateUrl: './site-footer.component.html',
    styleUrls: ['./site-footer.component.scss']
})
export class SiteFooterComponent {
    year = new Date().getFullYear();
    environment = environment;
    versionClickCount = 0;

    showVersion(event: MouseEvent) {
        this.versionClickCount += 1;
        setTimeout(() => this.versionClickCount -= 1, 2000);

        if (this.versionClickCount > 5) {
            alert(`TYT.com version ${environment.version}`);
        }
    }
}