import { ContentBlockType } from '@tytapp/content';
import { ImageBlock } from './image-block';

export * from './image-block';

export const IMAGE_BLOCK_TYPE: ContentBlockType<ImageBlock> = {
    id: 'image',
    label: 'Image',
    icon: 'image',
    template: {

    },
    preview: {
        image: '/assets/operation-hope/support-c2a-bg.png'
    },
    previewSettings: {
        horizontalAlign: 'center',
        verticalAlign: 'top'
    },
    deferredEditorComponent: () => import('./image-block.edit').then(m => m.EditorComponent),
    deferredViewComponent: () => import('./image-block.view').then(m => m.ViewComponent)
};
