import { ApiConfiguration } from '../api';
import { environment } from '../environment';

export function ApiConfigurationFactory() {
    let apiConfig = new ApiConfiguration();

    apiConfig.withCredentials = true;
    apiConfig.basePath = environment.urls.api;

    return apiConfig;
}