/// <reference path="../../../local-typings/cast.d.ts" />

import { ElementRef, NgZone, inject } from '@angular/core';
import { MediaService, PlaybackSession, AssetResolution, PlaybackHints, GoogleCastService, MediaItem, PlaybackState } from '@tytapp/media-playback';
import { Injectable } from '@angular/core';
import { Subject, Observable, BehaviorSubject, Subscription } from 'rxjs';
import { ApiAvAsset } from '@tytapp/api';
import { LoggerService, sleep } from '@tytapp/common';

export class CastRemotePlaybackSession implements PlaybackSession {
    constructor(
        public ngZone: NgZone,
        public logger: LoggerService,
        public service: CastRemotePlaybackService,
        public castService: GoogleCastService,
        public asset: ApiAvAsset,
        private _element: HTMLElement,
        private playbackItem: MediaItem,
        private deviceNameElement: HTMLElement,
        private initialPosition: number
    ) {
        this.position = initialPosition;

        this.createRemotePlayer();
        this.updateDeviceName();

        this.updateInterval = setInterval(() => this.ngZone.runGuarded(() => this.updateState()), 1000);
        this._playStateChanged.next(this._playState);
    }

    readonly enableSeekOnStart = false;
    readonly pauseOnDismiss = false;
    readonly pauseOnTakeOver = false;
    readonly pauseOnDestroy = false;
    readonly videoDisabled = false;

    get playState() {
        return this._playState;
    }

    updateDeviceName() {
        this.deviceNameElement.innerText = this.castService.currentSession?.getCastDevice()?.friendlyName ?? 'Unknown Device';
    }

    get item() {
        return this.playbackItem;
    }

    get castHasMedia() {
        return !!this.castService.currentSession?.getMediaSession()?.info?.contentId;
    }

    readonly isCastRemoteSession: boolean = true;

    get isCurrentMedia() {
        let media = this.castService.currentSession?.getMediaSession()?.info;
        if (!media)
            return false;

        let contentID = media.contentId;
        let expectedID: string;

        if (this.playbackItem && this.playbackItem.item && this.playbackItem.item.asset) {
            expectedID = `${this.playbackItem.item.asset.provider}:${this.playbackItem.item.asset.url}`;
        }

        console.log(`Remote is playing ${contentID}, this is a session for ${expectedID}`);

        return contentID == expectedID;
    }

    private updateInterval;

    private createRemotePlayer() {
        this.playbackStateSubscription?.unsubscribe();
        this.playbackStateSubscription = this.castService.playbackStateChanged.subscribe(() => this.updateState());
    }

    private playbackStateSubscription: Subscription;

    private destroyRemotePlayer() {
        this.playbackStateSubscription.unsubscribe();
    }

    private updateState() {
        if (!this.castService.currentSession) {
            console.warn(`[CastRemotePlayback] updateState(): no current session`);
            return;
        }

        if (!this.castService.currentSession?.getMediaSession()) {
            console.warn(`[CastRemotePlayback] updateState(): no current media`);
            return;
        }

        if (!this.isCurrentMedia) {
            console.warn(`[CastRemotePlayback] updateState(): not the current media`);
            return;
        }

        //console.log(`[CastRemotePlayback] updateState() updating...`);
        // if (this.castService.playbackState.duration == 0) {
        //     this.destroyRemotePlayer();
        //     this.createRemotePlayer();
        // }

        this.position = this.castService.playbackState.currentTime;
        this.length = this.castService.playbackState.duration;

        this._positionChanged.next(this.position);
        this._lengthChanged.next(this.length);

        let state: PlaybackState = this.castService.playbackState.playbackState;
        if (state === 'buffering')
            state = 'playing';

        console.log(`state: ${state}`);

        this._playState = state;
        this._playStateChanged.next(state);
    }

    public get element() {
        return { nativeElement: this._element };
    }

    readonly isGoogleCastSession: boolean = true;

    private _positionChanged: Subject<number> = new Subject<number>();
    private _lengthChanged: Subject<number> = new Subject<number>();
    private _finished: Subject<void> = new Subject<void>();
    private _playState: PlaybackState = 'unstarted';
    private _playStateChanged: BehaviorSubject<PlaybackState> = new BehaviorSubject<PlaybackState>(this._playState);
    private _volumeChanged: Subject<number> = new Subject<number>();
    private _onSeek: Subject<number> = new Subject<number>();

    position: number;
    length: number;
    hasChat: boolean;
    chatEmbed: string;
    seekable?: boolean;
    popOutURL?: string;
    volume: number;
    started: boolean = false;

    get onSeek(): Observable<number> {
        return this._onSeek;
    }

    get positionChanged(): Observable<number> {
        return this._positionChanged;
    }

    get lengthChanged(): Observable<number> {
        return this._lengthChanged;
    }

    get finished(): Observable<void> {
        return this._finished;
    };

    get playStateChanged(): Observable<PlaybackState> {
        return this._playStateChanged;
    }

    get volumeChanged(): Observable<number> {
        return this._volumeChanged;
    }

    async pause(): Promise<void> {
        if (!this.isCurrentMedia) {
            console.warn(`[CastRemotePlayback] pause(): not current media`);
            return;
        }

        let session = this.castService.currentSession?.getMediaSession();
        if (!session) {
            console.warn(`[CastRemotePlayback] pause(): no current session`);
            return;
        }

        try {
            await session.pause();
        } catch (e) {
            this.logger.error(`GoogleCast/RemotePlaybackSession: Caught error while sending pause() message to Google Cast:`);
            this.logger.error(e);
        }
        this.updateState();
    }

    async resume(): Promise<void> {
        let session = this.castService.currentSession?.getMediaSession();

        this.logger.info(`Resume.... invalidSession=${!session}, isCurrentMedia=${this.isCurrentMedia}`);
        if (!this.isCurrentMedia || !session) {
            this.logger.info('Not the current media...');

            this.logger.info('Cast is ready...');
            // We need to change media on the cast device...
            this.logger.info(`GoogleCast/Remote: Loading new media onto Cast device...`);
            this.logger.inspect(this.playbackItem);

            try {
                await this.castService.loadMedia(
                    this.playbackItem.item.asset,
                    this.position
                );
            } catch (e) {
                throw new Error(`[CastRemotePlayback] Error while loading media: ${e}`);
            }
        }

        if (!session)
            return;

        if (!this.castHasMedia) {
            this.logger.info(`GoogleCast/Remote: Received play() but Cast is not ready for it yet!`);
            return;
        }

        try {
            await session.play();
        } catch (e) {
            this.logger.error(`GoogleCast/RemotePlaybackSession: Caught error while sending play() message to Google Cast:`);
            this.logger.error(e);
        }
        this.updateState();
    }

    async setVolume(level: number) {
        if (!this.isCurrentMedia)
            return;

        let session = this.castService.currentSession?.getMediaSession();

        if (!session)
            return;

        try {
            await session.setVolume(level);
            this._volumeChanged.next(level);
        } catch (e) {
            this.logger.error(`GoogleCast/RemotePlaybackSession: Caught error while sending setVolume() message to Google Cast:`);
            this.logger.error(e);
        }

    }

    async seek(position: number) {
        if (!this.isCurrentMedia)
            return;

        // TODO: ABSTRACT

        let session = this.castService.currentSession?.getMediaSession();
        if (!session)
            return;

        this._onSeek.next(position);
        this.logger.info(`Seeking to ${position}`);

        try {
            await session.seek(position);
        } catch (e) {
            this.logger.error(`GoogleCast/RemotePlaybackSession: Caught error while sending seek() message to Google Cast:`);
            this.logger.error(e);
        }
    }

    async destroy() {
        this.destroyRemotePlayer();
        clearInterval(this.updateInterval);
        this._element.remove();
        await sleep(1);
    }
}

/**
 * HLS playback service
 */
@Injectable()
export class CastRemotePlaybackService implements MediaService {
    private ngZone = inject(NgZone);
    private castService = inject(GoogleCastService);
    private logger = inject(LoggerService);

    /**
     * Priority of this playback service amongst the registered playback services.
     * Regular services have a priority of zero, and TYT.com tries services with a
     * higher priority number before services with a lower priority number.
     */
    get priority() {
        return 10;
    }

    get id() {
        return 'cast-remote';
    }

    resolve(item: MediaItem): AssetResolution {
        // Accept all media, we will forward it to the connected Google Cast device
        if (this.castService.currentSession) {
            return {
                item,
                service: this,
                asset: item.item.asset,
                data: {
                    item
                }
            };
        }
    }

    /**
     * Play the given media resolution within the given root element.
     */
    async play(mediaResolution: AssetResolution, rootElement: ElementRef, hints?: PlaybackHints): Promise<PlaybackSession> {
        if (!hints)
            hints = {};

        this.logger.info(`GoogleCast/Remote: Starting session for media:`);
        this.logger.inspect(mediaResolution);

        let item: MediaItem = mediaResolution.data.item;

        let containerElement: HTMLElement = rootElement.nativeElement;

        let castElement: HTMLElement = document.createElement('div');
        castElement.classList.add('remote-cast-player');

        castElement.innerHTML = `
            <div class="cast-playback-element">
                <div class="underlay"></div>
                <div class="overlay">
                    <div>
                        <img src="/assets/cast/connected.png" style="height: auto;" />
                    </div>
                    <div class="text">
                        Casting to <strong><span class="device-name"></span></strong>
                    </div>
                </div>
            </div>
        `;

        containerElement.appendChild(castElement);

        let deviceNameElement: HTMLElement = castElement.getElementsByClassName('device-name')[0] as any;

        let session = new CastRemotePlaybackSession(
            this.ngZone,
            this.logger,
            this,
            this.castService,
            mediaResolution.asset,
            castElement,
            item,
            deviceNameElement,
            hints.startPosition || 0
        );

        if (hints.autoplay)
            session.resume();

        return session;
    }
}
