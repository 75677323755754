import { ContentBlockType } from '@tytapp/content';
import { AnnouncementsBlock } from './announcements-block';

export * from './announcements-block';

export const ANNOUNCEMENTS_BLOCK_TYPE: ContentBlockType<AnnouncementsBlock> = {
    id: 'announcements',
    label: 'Announcements',
    icon: 'newspaper',
    editable: false,
    template: {
    },
    preview: {
    },
    deferredEditorComponent: () => import('./announcements-block.edit').then(m => m.EditorComponent),
    deferredViewComponent: () => import('./announcements-block.view').then(m => m.ViewComponent)
}
