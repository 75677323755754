import { ElementRef, NgZone, inject } from '@angular/core';
import { MediaService, PlaybackSession, AssetResolution, HTMLMediaPlaybackSession, PlaybackHints, MediaItem } from '@tytapp/media-playback';
import { Injectable } from '@angular/core';
import { LoggerService } from '@tytapp/common';

/**
 * HLS playback service
 */
@Injectable()
export class HLSPlaybackService implements MediaService {
    private ngZone = inject(NgZone);
    private logger = inject(LoggerService);

    get id() {
        return 'hls';
    }

    resolve(item: MediaItem): AssetResolution {
        let asset = item.item.asset;
        let url = asset.url;

        if (!url.endsWith('.m3u8'))
            return null;

        return {
            item,
            service: this,
            asset,
            data: {}
        };
    }

    /**
     * Play the given media resolution within the given root element.
     */
    async play(mediaResolution: AssetResolution, rootElement: ElementRef, hints?: PlaybackHints): Promise<PlaybackSession> {
        let Hls = (await import('hls.js')).default;
        let assetUrl = mediaResolution.asset.url;
        let video = document.createElement('video');

        if (!hints)
            hints = {};

        let autoplay = hints.autoplay !== false;
        video.controls = false;
        video.autoplay = autoplay;
        if ('autoPictureInPicture' in video)
            (video as any).autoPictureInPicture = true;
        video.setAttribute('playsinline', 'true');

        let preferNativeHls = false;

        let userAgent = navigator.userAgent;
        if (userAgent.includes('Safari/') && !userAgent.includes('Chrome/')) {
            this.logger.info(`HlsPlayback: Safari detected: preferring native HLS`);
            preferNativeHls = true;
        }

        let startPosition = (mediaResolution.item.item.type === 'live_stream') ? -1 : 0;

        let useHlsJs = () => {
            this.ngZone.runOutsideAngular(() => {
                var hls = new Hls({ startPosition });
                hls.loadSource(assetUrl);
                hls.attachMedia(video);
            });
        };

        let useNativeHls = () => {
            video.src = assetUrl;
        };

        let cannotPlay = false;
        if (preferNativeHls) {
            if (video.canPlayType('application/vnd.apple.mpegurl'))
                useNativeHls();
            else if (Hls.isSupported())
                useHlsJs();
            else
                cannotPlay = true;
        } else {
            if (Hls.isSupported())
                useHlsJs();
            else if (video.canPlayType('application/vnd.apple.mpegurl'))
                useNativeHls();
            else
                cannotPlay = true;
        }

        if (cannotPlay) {
            this.logger.error(`No suitable HLS implementation available!`);
            alert('Uh oh! A problem occurred while trying to play back this media. This browser may not be supported.');
        }

        video.addEventListener('loadedmetadata', async () => {
            if (startPosition >= 0)
                video.currentTime = startPosition;
            if (autoplay) {
                try {
                    await video.play();
                } catch (e) {
                    this.logger.error(`Caught error while playing during autoplay:`);
                    this.logger.error(e);
                }
            }
        });
        rootElement.nativeElement.appendChild(video);

        return new HTMLMediaPlaybackSession(mediaResolution.item, this, video, mediaResolution.asset, hints?.startPosition);
    }
}
