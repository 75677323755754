<footer>
  <div role="img"
    responsiveBackgroundImage="/assets/tyt-light.png" style="height: 1.5em; aspect-ratio: 273 / 40"
    aria-label="TYT Network logo"
    ></div>
  <p class="copyright" title="Environment: {{environment.name}}" (click)="showVersion($event)">
      © Copyright {{year || 2021}} The Young Turks, Inc. All Rights Reserved.&nbsp;
  </p>
  <div class="links">
    <a routerLink="/terms">Terms of Use</a>
    | <a routerLink="/privacy">Privacy Policy</a>
    | <a routerLink="/values">TYT Core Values</a>
  </div>
</footer>