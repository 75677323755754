import { ContentBlock, ContentBlockType } from '@tytapp/content';

export interface OfferSetBlock extends ContentBlock {
    offerId: string;
}

export const OFFER_SET_BLOCK_TYPE: ContentBlockType<OfferSetBlock> = {
    id: 'offer_set',
    label: 'Offer Set',
    icon: 'request_quote',
    template: {

    },
    preview: {
        offerId: 'default'
    },
    previewSettings: {
        fade: true
    },
    deferredEditorComponent: () => import('./offer-set-block.edit').then(m => m.EditorComponent),
    deferredViewComponent: () => import('./offer-set-block.view').then(m => m.ViewComponent)
};
