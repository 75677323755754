import { Component, ElementRef, inject, Input, ViewChild } from '@angular/core';
import { AnalyticsService } from '@tytapp/analytics';
import { AppConfig } from '@tytapp/common';


@Component({
    selector: 'tyt-basic-search',
    templateUrl: './basic-search.component.html',
    styleUrls: ['./basic-search.component.scss']
})
export class BasicSearchComponent {
    private appConfig = inject(AppConfig);
    private analytics = inject(AnalyticsService);

    @Input() offset: string;
    enableAdvancedSearch: boolean;
    enableSearch: boolean;
    unique = Math.random() * 10000 | 0;

    @ViewChild('searchToggle', { read: ElementRef }) searchToggleRef: ElementRef;


    async ngOnInit() {
        this.enableAdvancedSearch = await this.appConfig.featureEnabled('apps.web.enable_advanced_search');
        this.enableSearch = await this.appConfig.featureEnabled('apps.web.enable_search');
    }

    preFormatter(params: URLSearchParams) {
        params.set('q', params.get('q').trim());
    }

    toggleExpanded() {
        const element = this.searchToggleRef.nativeElement;
        const expanded = element.getAttribute('aria-expanded') === 'true';
        element.setAttribute('aria-expanded', `${ !expanded }`);
    }

    analyticsHandler(params: URLSearchParams) {
        const terms = params.get('q');
        this.analytics.sendSearchAnalytics('search', terms);
    }
}
