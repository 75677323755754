import { isServerSide } from '@tytapp/environment-utils';
import { YouTube } from './types';

/**
 * The Loader
 */
export class YouTubeLoader {
    private static src = 'https://www.youtube.com/iframe_api';

    private static loading: boolean = false;
    private static loaded: boolean = false;
    private static listeners: any[] = [];

    static load(callback: (YT: YouTube) => void) {
        var _this = this;
        this.listeners.push(callback);

        if (this.loaded) {
            setTimeout(function () {
                _this.done();
            });
            return;
        }

        if (this.loading) {
            return;
        }

        this.loading = true;

        if (isServerSide())
            return;

        window['onYouTubeIframeAPIReady'] = function () {
            _this.loaded = true;
            _this.done();
        };

        var script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = this.src;
        document.body.appendChild(script);
    }

    static done() {
        delete window['onYouTubeIframeAPIReady'];

        while (this.listeners.length) {
            this.listeners.pop()(window['YT']);
        }
    }
};
