import { Injectable, inject } from '@angular/core';
import { ApiUser } from '@tytapp/api';
import { NotificationsService } from '@tytapp/notifications';
import { UserService } from '@tytapp/user';

@Injectable()
export class AccountAlertsService {
    private userService = inject(UserService);
    private notifications = inject(NotificationsService);

    init() {
        this.userService.userChanged.subscribe(user => this.checkAccountConfirmation(user));
    }

    private checkAccountConfirmation(user: ApiUser) {
        if (user && !user.confirmed) {
            this.notifications.add({
                type: 'local',
                timestamp: new Date(),
                id: 'accountConfirmation',
                category: 'info',
                style: 'attention',
                text: 'Email validation required',
                description: 'Confirm your account to access all features of TYT.com',
                url: '/validate-email'
            });
        } else {
            this.notifications.removeById('accountConfirmation');
        }
    }
}