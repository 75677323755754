import { Component, inject } from '@angular/core';
import { AppConfig, DialogComponent, HostApi, NamedDialog, Shell } from '@tytapp/common';
import { environment } from '@tytapp/environment';

@NamedDialog('update')
@Component({
    template: `
        <tyt-not-found
            [checkRedirect]="false" [checkForUpdates]="false"
            class="long-title bright"
            title="An update is available"
            message="We recommend updating {{product}} for the best experience. Please visit {{storeName}} and update!"
            />

            @if (storeLink) {
                <a class="store-link" [url]="storeLink">
                    @if (storeImage) {
                        <img [src]="storeImage" />
                    } @else {
                        Update Now
                    }
                </a>
            }

        <div class="actions">
            <button mat-raised-button color="primary" (click)="close()">
                Not Now
            </button>
        </div>
    `,
    styles: [`
        :host {
            width: 700px !important;
        }

        .store-link {
            display: block;
            text-align: center;
            margin-bottom: 1em;
        }
        .store-link img {
            max-width: 100%;
            width: 13em;
        }
    `]
})
export class UpgradeDialogComponent extends DialogComponent {
    private appConfig = inject(AppConfig);
    private hostApi = inject(HostApi);

    product = environment.productName;
    version: number;
    minVersion: number;
    storeName: string;
    storeLink: string;
    storeImage: string;

    async init() {
        await this.appConfig.appStatusReady;
        await this.hostApi.capabilitiesReady;

        this.version = Number(this.hostApi.capabilitiesMessage?.version ?? Infinity);
        this.minVersion = this.appConfig.appStatus.settings['min_version'];

        if (this.version < this.minVersion) {
            this.shell.dialogClosable = false;
        }

        if (['android', 'androidtv'].includes(this.hostApi.capabilitiesMessage?.platform)) {
            this.storeName = 'the Google Play Store';
            this.storeLink = environment.urls.playStoreUrl;
            this.storeImage = '/assets/appstore/google/black.png';
        } else if (['ios', 'appletv'].includes(this.hostApi.capabilitiesMessage?.platform)) {
            this.storeName = 'the App Store';
            this.storeLink = environment.urls.appStoreUrl;
            this.storeLink = 'https://apps.apple.com/us/app/tyt-home-of-progressives/id1319576607';
            this.storeImage = '/assets/appstore/apple/black.svg';
        } else {
            this.storeName = 'TYT.com';
            this.storeLink = `/--reinstall`;
        }
    }
}