import { NgModule } from "@angular/core";
import { MediaServiceRegistry } from "@tytapp/media-playback";
import { HTMLVideoPlaybackService } from "./html-video-playback";

@NgModule({
    providers: [
        HTMLVideoPlaybackService
    ]
})
export class TYTHTMLVideoModule {
    constructor(
        mediaServices: MediaServiceRegistry,
        mediaService: HTMLVideoPlaybackService
    ) {
        mediaServices.register(mediaService);
    }
}