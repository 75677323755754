import { ContentBlockType } from '@tytapp/content';
import { TextBlock } from './text-block';

export * from './text-block';

export const TEXT_BLOCK_TYPE: ContentBlockType<TextBlock> = {
    id: 'text',
    label: 'Text (Markdown)',
    icon: 'notes',
    template: {
        body: '',
        format: 'markdown'
    },
    preview: {
        body: 'The *quick* **brown** fox jumps [over](https://google.com) the ~~lazy~~ dog'
    },
    previewSettings: {
        horizontalAlign: 'center',
        verticalAlign: 'center'
    },
    deferredEditorComponent: () => import('./text-block.edit').then(m => m.EditorComponent),
    deferredViewComponent: () => import('./text-block.view').then(m => m.ViewComponent)
}