import { NgModule } from "@angular/core";
import { TYTYouTubeModule } from "./youtube/youtube.module";
import { TYTHLSModule } from "./hls/hls.module";
//import { TYTDashModule } from "./dash/dash.module";
import { TYTJWModule } from "./jw/jw.module";
import { TYTHTMLAudioModule } from "./html-audio/html-audio.module";
import { TYTHTMLVideoModule } from "./html-video/html-video.module";
import { TYTCastRemoteModule } from "./cast-remote/cast-remote.module";

const PLUGINS = [
    TYTYouTubeModule,
    TYTHLSModule,
    //TYTDashModule,
    TYTJWModule,
    TYTHTMLVideoModule,
    TYTHTMLAudioModule,
    TYTCastRemoteModule
];

@NgModule({
    imports: PLUGINS
})
export class PluginsModule {
}