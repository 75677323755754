/**
 * DIALOG REGISTRY
 *
 * All lazy-loaded dialogs must be registered here.
 * This is used to match a string name within a history entry to a dialog class which may
 * be part of a lazy-loaded module. Dialogs that are defined within built-in modules (ie anything that is not
 * part of a loadChildren definition) do not need to be defined here.
 */

import { Shell } from '@tytapp/common';

Shell.registerLazyDialogs(() => import('./pages/podcast-pages/podcast-pages.module'), register => {
    register('podcast', m => m.PodcastComponent);
});

Shell.registerLazyDialogs(() => import('./pages/petition-pages/petition-pages.module'), register => {
    register('missionTaskCompleted', m => m.TaskCompletedComponent);
});
