import { Injectable, ErrorHandler, inject } from '@angular/core';
import { Subject } from 'rxjs';
import { BugsnagErrorHandler } from './bugsnag-error-handler';
import * as sourcemappedStacktrace from 'sourcemapped-stacktrace';
import { environment } from '@tytapp/environment';
import { ApiAppConfig } from '@tytapp/api';
import { LoggerService } from '@tytapp/common/logger.service';
import Bugsnag from '@bugsnag/js';
import { isClientSide, isOnline, isServerSide, OfflineError } from '@tytapp/environment-utils';
import { DurationPipe } from '@tytapp/common/duration.pipe';
import { NavigationCancel, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { UserService } from '@tytapp/user';
import { ApiConnectionError, ApiResponseError } from '@tytapp/api/requester';

@Injectable()
export class AppErrorHandler extends ErrorHandler {
    private router = inject(Router);
    private logger = inject(LoggerService);
    private bugsnag = new BugsnagErrorHandler();
    private userService = inject(UserService);

    constructor() {
        super();

        this.router.events.subscribe(ev => {
            if (ev instanceof NavigationStart) {
                this.routerLog.push(`[NavigationStart] ${ev.url}`);
            } else if (ev instanceof NavigationEnd) {
                this.routerLog.push(`[NavigationEnd] ${ev.url}`);
                this.navigationLog.push(ev.url);
            } else if (ev instanceof NavigationCancel) {
                this.routerLog.push(`[NavigationCancel] ${ev.url}`);
            }

            this.routerLog = this.routerLog.slice(-this.maxRouterLogs);
            this.navigationLog = this.routerLog.slice(-this.maxNavigationLogs);
        });
    }

    public appStatus: ApiAppConfig;
    panicked: boolean = false;
    private _networkError: Subject<any> = new Subject<any>();
    private routerLog: string[] = [];
    private maxRouterLogs = 50;
    private navigationLog: string[] = [];
    private maxNavigationLogs = 50;

    public get networkError() { return this._networkError.asObservable(); }

    /**
     * Handle an uncaught error.
     * @param error
     */
    handleError(error) {
        try {
            this.doHandleError(error);
        } catch (e) {
            this.logger.error("(FATAL) Caught an error while handling a top-level error! This implies a bug in the error handling routine itself!");
            this.logger.error(`  ||    Message: ${e.message}`);
            this.logger.error(`  ||    Stack: ${e.stack}`);
        }
    }

    private doHandleError(error) {
        let clientSide = isClientSide();

        while (error.rejection) {
            if (clientSide) {
                console.groupCollapsed(`Stripping promise rejection layer from original error data:`);
                console.dir(error);
                console.groupEnd();
            }
            error = error.rejection;
        }

        // Skip this specific YouTube error

        if (error.message && error.message == 'The YouTube player is not attached to the DOM.')
            return;

        // This might be an error fetching an API request, lets handle that specially
        let isNetworkError =
            error instanceof ApiConnectionError
            || (typeof ProgressEvent !== 'undefined' && (error instanceof ProgressEvent && error.type === 'error'))
            || error.errorType === 'network-error'
            || error instanceof OfflineError;

        if (isNetworkError) {
            this._networkError.next(error);
            return;
        }

        // ---------------------------------------
        // This is a genuine error.

        try {
            this.bugsnag.handleError(error);
        } catch (e) {
            console.error(`Bugsnag error handler threw an error`);
        }


        if (environment.testing) {
            if (error.stack)
                this.logger.error(`Error: ${error.message} -- Stack: ${error.stack}`);
            else
                this.logger.error(`Error: ${error.message}`);

            try {
                sourcemappedStacktrace.mapStackTrace(error.stack, mappedStack => {
                    this.logger.error(`Sourcemapped stack trace for error '${error.message}': ${mappedStack.join("\n")}`);
                });
            } catch (e) {
                console.error(`[ErrorHandler] Failed to sourcemap stack trace:`);
                console.error(e);
            }
            return;
        }

        if (this.panicked)
            return;

        let side: string = isServerSide() ? 'server-side' : 'client-side';

        if (error.message && error.message.includes('#_=_')) {
            return;
        }

        if (isClientSide() && error.message && /Loading chunk \d+ failed./.test(error.message)) {
            // Looks like our application version is no longer available.
            // This should be a rare circumstance. Report the event to the server and attempt to gracefully recover.

            this.logger.error(`${error.message}, details: ${error}`);

            Bugsnag.notify(new Error(`The application version on-server was replaced and we could not load a chunk. (${side})`));

            // Attempt to reload the page (only if we can guarantee that it will happen exactly once)
            // Hopefully the user will get the new app version with a minor inconvenience

            if (window.localStorage) {
                let allowReload = true;

                if (window.location.pathname === '/cast/receiver')
                    allowReload = false;

                if (window.localStorage['appVersionFailureDate']) {
                    let lastDate = parseInt(window.localStorage['appVersionFailureDate']);

                    if (new Date().getTime() - 1000 * 60 < lastDate) {
                        allowReload = false;
                    }
                }

                if (allowReload)
                    window.location.reload();
            }
        }

        this.panicked = true;
        let shownError: Error = error;

        if (error.panic)
            shownError = error.panicError;

        if (shownError.stack) {
            this.logger.error(`Panic: ${shownError.stack}`);
        } else {
            try {
                this.logger.error(`Panic: ${this.jsonCycles(shownError)})}`);
            } catch (e) {
                this.logger.error(`Panic: (Failed to render error: ${e.message})`);
            }
        }

        if (environment.showPanics) {
            this.showPanic(shownError);

            // If the user navigates, force a page refresh. This prevents getting "stuck" in the
            // panic screen.

            if (isClientSide()) {
                window.addEventListener('popstate', () => {
                    this.logger.warning(`Reloading on back-navigation due to panic`);
                    window.location.reload();
                });
            }

            return;
        }
    }

    private jsonCycles(object) {
        let nextId = 0;
        let map = new WeakMap();

        return JSON.stringify(object, (key, value) => {
            if (value && typeof value === 'object') {

                if (map.has(value)) {
                  return { $ref: map.get(value).id };
                } else {
                  let info = { id: nextId++ };
                  map.set(value, info);
                  return Object.assign({ $id: info.id }, value)
                }
            }

            return value;
        }, 2)
    }

    crashCount = 0;
    startedAt = Date.now();

    private generatePlugins() {
        if (!('plugins' in navigator)) {
            return `<User agent does not enumerate plugins>`;
        }

        try {
            let pluginList: string[] = [];
            for (let i = 0, max = navigator.plugins.length; i < max; ++i) {
                let plugin = navigator.plugins.item(i);
                pluginList.push(` - ${plugin.name} [${plugin.filename}]: ${plugin.description}`);
            }

            return pluginList.join("\n");
        } catch (e) {
            return `<Error generating plugin list>`;
        }
    }

    private generateQuip() {

        let quips = [
            `That wasn't supposed to happen.`,
            `That's never happened before.`,
            `Score!`,
            `Question: How many programmers does it take to change a light bulb?\n`
                + `Answer: What’s the problem? The bulb at my desk works fine!`,
            `To an optimist, the glass is half full.\n`
                + `To a pessimist, the glass is half empty.\n`
                + `To a good tester, the glass is twice as big as it needs to be.`,
            `Developer: There is no I in TEAM\n` +
                `Tester: We cannot spell BUGS without U`,
            `Law of Cybernetic Entomology: There is always one more bug.`,
            `When your hammer is C++, everything begins to look like a thumb.`,
            `It puzzles me. The minute I hand the code out to you people, it does not work anymore`,
            `Weinberg's Second Law: If builders built buildings the way programmers wrote programs, then the first woodpecker that came along would have destroyed civilization.`,
            `To tell somebody that he is wrong is called criticism. To do so officially is called testing.`,
            `How did we get here?`,
            `Blame Liam.`,
            `It's better to have loved and lost, than to never have loved at all.`,
            `All's fair in love and war.`,
            `I hope this wasn't deployed on a Friday.`,
            `Time to go to Trello!`,
            `Aaannnnd Defective(TM)!`,
            `QA strikes again!`,
            `Don't forget to thank your QA team! 💖`,
            `Let's just pretend this didn't happen.`,
            `Bug? This is more like a tarantula!`,
            `Boom!`,
            `Welp, we tried.`,
            `I hope you don't have to see this all day today`,
            `We engineer'd it good!`,
            `Let's throw it back to Dev and get a new release in Circle ASAP`,
            `So much for the acceptance criteria.`,
            `I swear this app works, sometimes.`,
            `Can we blame Apple?`,
            `Can we blame Google?`,
            `Can we blame Amazon?`,
            `Can we blame Trump?`,
            `Oh no...`,
            `Bingo!`,
            `Jackpot!`
        ];
        return quips[Math.random()*quips.length|0];
    }

    private generateDiagnostics() {
        return ``
            + `Time: ${new Date()}\n`
            + `User: ${this.userService.identity?.uuid ?? '<Not signed in>'}\n`
            + `User Agent: ${navigator.userAgent}\n`
            + `Platform: ${navigator.platform ?? 'Unknown'}\n`
            + `Density: ${window.devicePixelRatio ?? 'Unknown'}\n`
            + `Agent Memory: ${navigator['deviceMemory'] ?? 'N/A'}\n`
            + `Network: ${navigator['connection']
                ? `${navigator['connection'].effectiveType ?? navigator['connection'].type ?? '<unknown type>'}`
                    + ` | RTT: ${navigator['connection'].rtt ?? 'N/A'}`
                    + ` | Downlink: ${navigator['connection'].downlink}`
                : `N/A`}\n`
            + `Cookies Enabled: ${navigator.cookieEnabled ? 'Yes' : 'No (!!)'}\n`
            + `Hardware Concurrency: ${navigator.hardwareConcurrency}\n`
            + `Online: ${isOnline() ? 'Yes' : 'No'}\n`
            + `DNT: ${navigator.doNotTrack}\n`
            + `Lang: ${navigator.language}\n`
            + `Build: ${environment.name}\n`
            + `Env: ${localStorage.getItem('tyt-api-override') ?? environment.name}\n`
            + `History length: ${history.length}\n`
            + `Tab hidden (at moment of error): ${document.hidden}\n`
            + `Referrer: ${document.referrer}\n`
            + `Prev Crashes: ${this.crashCount++}\n`
            + `In Frame: ${window !== window.parent ? 'Yes (!!)' : 'No'}\n`
            + `User Activity: Active: ${navigator['userActivation']?.isActive ?? '<Unknown>'}, Has been active: ${navigator['userActivation']?.hasBeenActive ?? '<Unknown>'}\n`
            + `Booted: ${new Date(this.startedAt)}\n`
            + (performance['memory'] ?
                `Memory Usage: ${performance['memory'].usedJSHeapSize} / ${performance['memory'].totalJSHeapSize} [limit: ${performance['memory'].jsHeapSizeLimit}]\n`
                : `Memory Usage: <not available>`
                )
            + `Uptime: ${new DurationPipe().transform((Date.now() - this.startedAt) / 1000, 'seconds')}`
        ;
    }

    private generateReport(error: string, diagnostics: string, quip: string, symbolized: boolean) {
        return ``
            + `${quip} \n`
            + `\n`
            + `======================\n`
            + `${diagnostics}\n`
            + `======================\n`
            + `\n`
            + `URL: ${window.location.href}\n`
            + `Symbolized: ${symbolized ? 'Yes' : 'No [please wait...]'}\n\n`
            + `${error}\n\n`
            + `Navigation History (last 50 URLs):\n`
            + `${this.navigationLog.map(x => ` - ${x}`).join(`\n`)}\n\n`
            + `Router History (last 50 events):\n`
            + `${this.routerLog.map(x => ` - ${x}`).join(`\n`)}\n\n`
            + `Cookies:\n`
            + `${document.cookie}\n\n`
            + `Plugins:\n`
            + `${this.generatePlugins()}\n\n`
        ;
    ;
    }

    /**
     * Show the user the panic page
     */
    private showPanic(shownError) {
        let envName = environment.name || 'development';
        let enableProductionPanic = true;

        if (!shownError)
            shownError = new Error("No error details are available");

        if (!enableProductionPanic || envName != 'production') {
            if (typeof document !== 'undefined') {
                let style = document.createElement('style');
                style.innerText = `
                    #crash-box {
                        overflow-y: hidden;
                        display: none;
                        position: fixed;
                        top: 0; left: 0;
                        bottom: 0; right: 0;
                        color: black;
                        z-index: 99999;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        background: #171921;
                    }

                    #crash-box .p-logo {
                        width: 2em;
                        display: inline-block;
                    }
                    #crash-box .p-logo .st0 {
                        fill: white;
                    }
                    #crash-box > div {
                        max-height: 100%;
                        overflow-y: auto;
                        margin: 0.5em;
                        padding: 1em;
                        width: 1000px;
                        max-width: 100%;
                        border: 1px solid #767676;
                        border-radius: 5px;
                        background: #21232b;
                        color: white;
                    }
                    #crash-box.visible {
                        display: flex !important;
                        justify-content: center;
                        align-items: center;
                    }

                    #crash-message * {
                        line-height: initial;
                    }

                    #crash-report {
                        width: 100%;
                        font-family: monospace;
                        background: transparent;
                        color: white;
                        height: 800px;
                        max-height: 70vh;
                        white-space: pre;
                        border: none;
                    }

                    @media (max-width: 450px) {
                        #crash-report {
                            height: 100vh;
                            font-size: 13px;
                        }
                    }

                    #crash-box button {
                        width: 100%;
                        background: rgba(0,0,0,0.5);
                        color: white;
                        border: none;
                        margin: 0.5em 0;
                        padding: 0.5em;
                    }
                `;

                let crashBox = document.createElement('div');
                crashBox.id = 'crash-box';
                crashBox.classList.add('visible');

                crashBox.innerHTML = `
                    <div>
                        <div style="display: flex; flex-direction: row; align-items: center;">
                        <hr style="flex: 1;" />
                        <div style="margin: 0 1em; text-transform: uppercase; color: #ccc; letter-spacing: 2px;">
                            <svg class="p-logo" class="cast-splash-logo" version="1.1" x="0px" y="0px"
                                viewBox="0 0 279.1 198.2" style="enable-background:new 0 0 279.1 198.2;" xml:space="preserve">

                                <path d="M265,0h-251C6.3,0,0,6.3,0,14.1v126.2c0,6,3.8,11.3,9.4,13.3L134,197.4c3,1,6.2,1.1,9.2,0.1l126.4-42.6
                                c5.7-1.9,9.6-7.3,9.6-13.3V14.1C279.1,6.3,272.8,0,265,0z"/>
                                <g>
                                <path class="st0" d="M94.6,49.1l-6.5-19.6c-0.4-1.2-1.5-2-2.7-2H24.6c-1.8,0-3.2,1.7-2.8,3.5l4.4,19.6c0.3,1.3,1.5,2.3,2.8,2.3
                                    h17.3c1.6,0,2.9,1.3,2.9,2.9v84c0,1.3,0.9,2.5,2.3,2.8l21.1,4.7c1.8,0.4,3.5-1,3.5-2.8V55.8c0-1.6,1.3-2.9,2.9-2.9h12.9
                                    C93.8,52.9,95.2,50.9,94.6,49.1z"/>
                                <path class="st0" d="M184.5,49.1l6.5-19.6c0.4-1.2,1.5-2,2.7-2h60.7c1.8,0,3.2,1.7,2.8,3.5l-4.4,19.6c-0.3,1.3-1.5,2.3-2.8,2.3
                                    h-17.3c-1.6,0-2.9,1.3-2.9,2.9v84c0,1.3-0.9,2.5-2.3,2.8l-21.1,4.7c-1.8,0.4-3.5-1-3.5-2.8V55.8c0-1.6-1.3-2.9-2.9-2.9h-12.9
                                    C185.2,52.9,183.9,50.9,184.5,49.1z"/>
                                <path class="st0" d="M154.2,29.5l-14.7,45.4l-14.7-45.4c-0.4-1.2-1.5-2-2.7-2h-21.7c-2,0-3.4,2-2.7,3.8l26.4,75.3
                                    c0.1,0.3,0.2,0.6,0.2,0.9l0.3,51.8c0,1.3,0.9,2.5,2.2,2.8l12.2,3c0.5,0.1,0.9,0.1,1.4,0l12.2-3c1.3-0.3,2.2-1.5,2.2-2.8l0.3-51.8
                                    c0-0.3,0.1-0.6,0.2-0.9l26.4-75.3c0.7-1.9-0.7-3.8-2.7-3.8h-21.7C155.6,27.5,154.5,28.3,154.2,29.5z"/>
                                </g>
                            </svg>
                            Uncaught Error
                        </div>
                        <hr style="flex: 1;" />
                        </div>
                        <div>
                            <textarea id="crash-report"></textarea>
                        </div>

                        <p>Please send the entire report (via text, not screenshot) to the developers.</em>
                        <button id="crash-copy">Copy report to clipboard</button>
                        <button id="crash-dismiss">Dismiss</button>
                    </div>
                `;
                document.body.appendChild(crashBox);
                document.body.appendChild(style);

                console.log(`ERROR:`);
                console.dir(shownError);

                if (typeof shownError === 'string' && shownError.includes('<!DOCTYPE html>')) {
                    let parser = new DOMParser();
                    let doc = parser.parseFromString(shownError, 'text/html');
                    shownError = `<div class="backend-html">${doc.body.innerHTML}</div>`;
                }

                let crashReport = document.getElementById('crash-report') as HTMLTextAreaElement;
                let copyButton = document.getElementById('crash-copy') as HTMLButtonElement;
                let dismissButton = document.getElementById('crash-dismiss') as HTMLButtonElement;

                copyButton.addEventListener('click', () => {
                    crashReport.select();
                    if (environment.isNativeBuild && document.documentElement.classList.contains('ios-os')) {
                        navigator.clipboard.writeText(crashReport.value);
                    } else {
                        document.execCommand?.('copy');
                    }

                    alert('Copied to clipboard.');
                });

                dismissButton.addEventListener('click', () => {
                    this.panicked = false;
                    crashBox.remove();
                    document.documentElement.style.overflowY = '';
                    document.body.style.overflowY = '';
                });

                let quip = this.generateQuip();
                let diagnostics = this.generateDiagnostics();

                let displayedError: string;

                if (shownError.stack) {
                    if (!(shownError instanceof ApiResponseError)) {
                        displayedError = String(shownError.stack);
                        if (shownError.message && !displayedError.includes(shownError.message)) {
                            displayedError = `${shownError.message}\n${displayedError}`
                        }
                    }
                } else {
                    displayedError = shownError;
                    try {
                        displayedError = JSON.stringify(shownError, undefined, 2)
                    } catch (e) {
                        console.error(`[ErrorHandler] Failed to serialize error to JSON: ${e.message}`);
                    }
                }

                debugger;

                crashReport.value = this.generateReport(displayedError, diagnostics, quip, false);

                if (shownError.stack) {
                    try {
                        sourcemappedStacktrace.mapStackTrace(shownError.stack, mappedStack => {
                            crashReport.value = this.generateReport(
                                `Error: ${shownError.$description || shownError.message}\n${mappedStack.join("\n")}\n`
                                + `\n`
                                + `Unmapped ${shownError.stack}`,
                                diagnostics, quip, true);
                        });
                    } catch (e) {
                        console.error(`[ErrorHandler] Failed to sourcemap stack trace:`);
                        console.error(e);
                    }
                }
            }
        } else {
            if (typeof document !== 'undefined') {
                document.getElementById('panic').className = 'visible';
            }
        }

        // Generic adjustments to document

        if (typeof document !== 'undefined') {
            document.documentElement.style.overflowY = 'hidden';
            document.body.style.overflowY = 'hidden';
        }
    }
}
