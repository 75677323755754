import { ContentBlockType } from '@tytapp/content';
import { CountdownBlock } from './countdown-block';

export * from './countdown-block';

export const COUNTDOWN_BLOCK_TYPE: ContentBlockType<CountdownBlock> = {
    id: 'countdown',
    label: 'Countdown',
    icon: 'hourglass_bottom',
    template: {
        expiresAt: "2030-01-01T08:01:00.000Z",
        endedText: "",
        format: 'short'
    },
    preview: {
        expiresAt: "2030-01-01T08:01:00.000Z",
        endedText: "",
        format: 'short'
    },
    deferredEditorComponent: () => import('./countdown-block.edit').then(m => m.EditorComponent),
    deferredViewComponent: () => import('./countdown-block.view').then(m => m.ViewComponent)
}
