import { ContentBlockType } from '@tytapp/content';
import { ButtonBlock } from './button-block';

export * from './button-block';

export const BUTTON_BLOCK_TYPE: ContentBlockType<ButtonBlock> = {
    id: 'button',
    label: 'Button(s)',
    icon: 'smart_button',
    template: {
        alignment: 'center',
        buttons: [
            {
                text: '',
                url: '/'
            }
        ]
    },
    preview: {
        alignment: 'center',
        buttons: [
            {
                text: 'Enter Text Here',
                style: 'primary',
                url: 'https://google.com'
            }
        ]
    },
    previewSettings: {
        horizontalAlign: 'center',
        verticalAlign: 'center'
    },
    deferredEditorComponent: () => import('./button-block.edit').then(m => m.EditorComponent),
    deferredViewComponent: () => import('./button-block.view').then(m => m.ViewComponent)
}