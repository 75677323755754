/// <reference types="jwplayer" />

import { ElementRef, Injectable, NgZone, inject } from '@angular/core';
import { ApiAvAsset } from '@tytapp/api';
import { LoggerService, sleep } from '@tytapp/common';
import { AssetResolution, MediaItem, MediaService, PlaybackHints, PlaybackSession, PlaybackState } from '@tytapp/media-playback';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

/**
 * JW playback service
 */
@Injectable()
export class JWPlaybackService implements MediaService {
    private zone = inject(NgZone);
    private logger = inject(LoggerService);

    get id() { return 'jw'; }

    resolve(item: MediaItem): AssetResolution {
        let asset: ApiAvAsset = item.item.asset;
        let url = asset.url;
        let match = url.match(/^jw:\/\/(.*)/);

        if (match) {

            let id = match[1];

            return {
                item,
                service: this,
                asset,
                data: {
                    id: id
                }
            };
        }
    }

    private styles = `
        .jw-player {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        .jw-player iframe {
            height: 100%;
            position: absolute;
            width: 100%;
        }
    `;

    private styleElement: HTMLElement = null;
    private jwScriptElement: HTMLScriptElement = null;

    private _jwInitPromise;

    jwInit() {
        if (this._jwInitPromise)
            return this._jwInitPromise;

        return this._jwInitPromise = new Promise<void>(resolve => {
            let playerUrl = 'https://content.jwplatform.com/libraries/rqjH7xEI.js';
            let key = 'aRDof39i4WGKoegJaz+yYQdAABkTtdtj0rihVcird1JQEgwy';
            let jwScriptEl: HTMLScriptElement = this.jwScriptElement;

            if (!jwScriptEl) {
                jwScriptEl = <any>document.getElementById(`jw-script`);
                if (!jwScriptEl) {
                    jwScriptEl = document.createElement('script');
                    jwScriptEl.src = playerUrl;
                    jwScriptEl.onload = () => {
                        jwplayer.key = key;
                        resolve();
                    };
                    jwScriptEl.id = 'jw-script';
                    document.head.appendChild(jwScriptEl);
                }

                this.jwScriptElement = jwScriptEl;
            } else {
                resolve();
            }
        });
    }

    /**
     * Play the given media resolution within the given root element.
     */
    async play(mediaResolution: AssetResolution, rootElement: ElementRef, hints?: PlaybackHints): Promise<PlaybackSession> {


        if (typeof document !== 'undefined') {

            // Ensure JW player is loaded in and has ze key
            await this.jwInit();

            // Ensure the global CSS styles are installed.

            let styleEl = this.styleElement;
            if (!styleEl) {
                styleEl = document.getElementById(`jw-styles`);
                if (!styleEl) {
                    this.logger.info("Making the JW styles");
                    styleEl = document.createElement('style');
                    styleEl.innerHTML = this.styles;
                    styleEl.id = 'jw-styles';
                    document.head.appendChild(styleEl);
                }

                this.styleElement = styleEl;
            }
        }

        // Construct a new element to hold the JW player

        let element = <HTMLElement>rootElement.nativeElement;
        let playerId = 'jwplayer_' + Math.floor(Math.random() * 10000);
        let id = mediaResolution.data['id'];

        element.innerHTML = `<div class="jw-player"><div id="${playerId}"></div></div>`;

        // Initialize the player and wait for it to be ready...

        return new Promise<PlaybackSession>((resolve, reject) => {
            let session: JWPlaybackSession = null;

            this.zone.runOutsideAngular(() => {

                let player = jwplayer(playerId);
                this.logger.inspect(player);

                window['jw_player'] = player;

                let options: any = {
                    width: '100%',
                    controls: false
                };

                if (mediaResolution.data.id) {
                    let playlistUrl = `https://content.jwplatform.com/jw6/${id}.xml`;
                    options.playlist = playlistUrl;
                } else {
                    options.file = mediaResolution.data.url;
                }

                player.setup(options);
                player.on('setupError', (e => {
                    this.logger.error(`JW failed setup: ${e.message}`);
                    this.logger.inspect(e);
                }));

                player.on('ready', () => {
                    player.play(true);

                    setTimeout(() => {
                        this.zone.run(() => {
                            session = new JWPlaybackSession(mediaResolution.item, this, mediaResolution, rootElement, player, this.zone, hints ? hints.startPosition : undefined);

                            if (hints.startPosition) {
                                setTimeout(() => {
                                    session.seek(hints.startPosition);
                                }, 250);
                            }

                            resolve(session);
                        });
                    }, 250);
                });

                player.on('error', e => {
                    this.logger.error(`JW playback failed: ${e.message}`);
                });
            });

        });
    }
}

/**
 * YouTube playback session (used by YouTubePlaybackService)
 */
export class JWPlaybackSession implements PlaybackSession {

    constructor(
        readonly item: MediaItem,
        service: JWPlaybackService,
        mediaResolution: AssetResolution,
        element: ElementRef,
        jwPlayer: JWPlayer,
        private zone: NgZone,
        private initialPosition: number
    ) {
        this._service = service;
        this._asset = mediaResolution.asset;
        this._resolution = mediaResolution;
        this._element = element;
        this._jwPlayer = jwPlayer;

        let playState: PlaybackState = null;

        this._positionInterval = setInterval(() => {
            this._positionChanged.next(this.position);

            let newDuration = jwPlayer.getDuration();

            if (this._length != newDuration) {
                this._length = newDuration;
                this._lengthChanged.next(this._length);
            }

            let newPlayState: PlaybackState = null;
            if (jwPlayer.getState() == "playing") {
                newPlayState = 'playing';
            } else if (jwPlayer.getState() == "paused") {
                newPlayState = 'paused';
            } else if (jwPlayer.getState() == "buffering") {
                newPlayState = 'buffering';
            }

            if (newPlayState != playState) {
                playState = newPlayState;
                this._playState = playState;
                this._playStateChanged.next(playState);
            }

        }, 500);

        this._length = jwPlayer.getDuration();
        this._lengthChanged.next(this._length);


        jwPlayer.on('complete', () => {
            this.zone.run(() => {
                this.onEnd();
            });
        });

        jwPlayer.on('pause', () => {
            this.zone.run(() => {
                playState = 'paused';
                this._playStateChanged.next(playState);
            });
        });

        jwPlayer.on('play', () => {

            if (this.initialPosition && !this.initialSeekCompleted) {
                this.seek(this.initialPosition);
                this.initialSeekCompleted = true;
            }

            this.zone.run(() => {
                playState = 'playing';
                this._playStateChanged.next(playState);
            });
        });

        jwPlayer.on('buffer', () => {
            this.zone.run(() => {
                playState = 'buffering';
                this._playStateChanged.next(playState);
            });
        });
    }

    get playState(): PlaybackState {
        return this._playState;
    }

    private _playState: PlaybackState;

    get volume() {
        return this.jwPlayer.getVolume() / 100.0;
    }
    private initialSeekCompleted = false;
    private _onSeek: Subject<number> = new Subject<number>();

    get onSeek(): Observable<number> {
        return this._onSeek;
    }

    onEnd() {
        this._finished.next();
    }

    stop() {
        this._jwPlayer.stop();
    }

    async destroy() {
        this.stop();
        clearInterval(this._positionInterval);
        this.jwPlayer.remove();
        await sleep(1);
    }

    private _finished = new Subject<void>();
    private _playStateChanged = new Subject<PlaybackState>();
    private _positionInterval;
    private _service: JWPlaybackService;
    private _asset: ApiAvAsset;
    private _resolution: AssetResolution;
    private _element: ElementRef;
    private _length: number;
    private _position: number;
    private _jwPlayer: JWPlayer;
    private _positionChanged: Subject<number> = new Subject<number>();
    private _lengthChanged: BehaviorSubject<number> = new BehaviorSubject<number>(0);

    get hasChat() {
        return false;
    }

    get chatEmbed() {
        return '<!-- nah bro -->';
    }

    get length() { return this._length; }
    get position() { return this.jwPlayer.getPosition(); }
    get service() { return this._service; }
    get element() { return this._element; }
    get asset() { return this._asset; }
    get positionChanged(): Observable<number> { return this._positionChanged; }
    get lengthChanged(): Observable<number> { return this._lengthChanged; }

    private get jwPlayer() {
        return this._jwPlayer;
    }

    async pause() {
        this.jwPlayer.pause(true);
    }

    async resume() {
        this.jwPlayer.play(true);
    }

    setVolume(level: number) {
        this.jwPlayer.setVolume(level * 100);

        if (level < 0.02) {
            this.jwPlayer.setMute(true);
        } else {
            this.jwPlayer.setMute(false);
        }

        this._volumeChanged.next(level);
    }

    private _volumeChanged: Subject<number> = new Subject<number>();
    get volumeChanged(): Observable<number> {
        return this._volumeChanged;
    }

    seek(positionInSeconds: number) {
        this.jwPlayer.seek(positionInSeconds);
        this._onSeek.next(positionInSeconds);
    }

    get finished(): Observable<void> {
        return this._finished;
    }

    get playStateChanged(): Observable<PlaybackState> {
        return this._playStateChanged;
    }
}
