import { CommonModule } from '@angular/common';
import { Injector, NgModule, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TYTCommonModule } from '@tytapp/common';
import { CommonUiModule } from '@tytapp/common-ui';
import { ContentModule } from '@tytapp/content';
import { ConditionVariable, ContentBlocksService } from '@tytapp/content';
import { EMBED_BLOCK_TYPE } from './embed-block';
import { HEADING_BLOCK_TYPE } from './heading-block';
import { SECTION_TAG_BLOCK_TYPE } from './section-tag-block';
import { TEXT_BLOCK_TYPE } from './text-block';
import { YOUTUBE_BLOCK_TYPE } from './youtube-block';
import { TWEET_BLOCK_TYPE } from './tweet-block';
import { IMAGE_BLOCK_TYPE } from './image-block';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { OFFER_SET_BLOCK_TYPE } from './offer-set-block';
import { ANCHOR_BLOCK_TYPE } from './anchor-block';
import { HERO_BLOCK_TYPE } from './hero-block';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { TEASER_LIST_BLOCK_TYPE } from './teaser-list-block';
import { FEATURE_LIST_BLOCK_TYPE } from './feature-list-block';
import { HONOR_ROLL_BLOCK_TYPE } from './honor-roll-block';
import { CONTENT_VIEW_BLOCK_TYPE } from './content-view-block';
import { TAB_GROUP_BLOCK_TYPE } from './tab-group-block';
import { MatTabsModule } from '@angular/material/tabs';
import { BUTTON_BLOCK_TYPE } from './button-block';
import { MatDividerModule } from '@angular/material/divider';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { COMMENT_BLOCK_TYPE } from './comment-block';
import { CONDITIONAL_BLOCK_TYPE } from './conditional-block';
import { NEWSLETTER_SIGNUP_BLOCK_TYPE } from './newsletter-signup-block';
import { PAID_SUPPORT_C2A_BLOCK_TYPE } from './paid-support-c2a-block';
import { ANNOUNCEMENTS_BLOCK_TYPE } from './announcements-block';
import { HOW_TO_WATCH_BLOCK_TYPE } from './how-to-watch-block';
import { ONBOARDING_C2A_BLOCK_TYPE } from './onboarding-c2a-block';
import { CAROUSEL_BLOCK_TYPE } from './carousel-block';
import { UserService } from '@tytapp/user';
import { OFFER_SET_PROGRESS_BLOCK_TYPE } from './offer-set-progress-block';
import { COUNTDOWN_BLOCK_TYPE } from './countdown-block';
import { TEXTING_OPTIN_BLOCK_TYPE } from './texting-optin-block';

const BLOCK_TYPES = [
    TEXT_BLOCK_TYPE,
    SECTION_TAG_BLOCK_TYPE,
    HEADING_BLOCK_TYPE,
    EMBED_BLOCK_TYPE,
    YOUTUBE_BLOCK_TYPE,
    TWEET_BLOCK_TYPE,
    IMAGE_BLOCK_TYPE,
    OFFER_SET_BLOCK_TYPE,
    OFFER_SET_PROGRESS_BLOCK_TYPE,
    ANCHOR_BLOCK_TYPE,
    HERO_BLOCK_TYPE,
    TEASER_LIST_BLOCK_TYPE,
    FEATURE_LIST_BLOCK_TYPE,
    HONOR_ROLL_BLOCK_TYPE,
    CONTENT_VIEW_BLOCK_TYPE,
    TAB_GROUP_BLOCK_TYPE,
    BUTTON_BLOCK_TYPE,
    COMMENT_BLOCK_TYPE,
    CONDITIONAL_BLOCK_TYPE,
    COMMENT_BLOCK_TYPE,
    NEWSLETTER_SIGNUP_BLOCK_TYPE,
    TEXTING_OPTIN_BLOCK_TYPE,
    PAID_SUPPORT_C2A_BLOCK_TYPE,
    ANNOUNCEMENTS_BLOCK_TYPE,
    HOW_TO_WATCH_BLOCK_TYPE,
    ONBOARDING_C2A_BLOCK_TYPE,
    CAROUSEL_BLOCK_TYPE,
    COUNTDOWN_BLOCK_TYPE
];

function lifetimeBlocks(name: string, label: string, lifetimeInMs: (injector: Injector) => Promise<number>): ConditionVariable[] {
    return [
        {
            name: `${name}Years`,
            label: `${label} (Years)`,
            type: 'number',
            value: async i => ((Date.now() - (await lifetimeInMs(i))) / 1000 / 60 / 60 / 24 / 365) | 0
        },
        {
            name: `${name}Months`,
            label: `${label} (Months)`,
            type: 'number',
            value: async i => ((Date.now() - (await lifetimeInMs(i))) / 1000 / 60 / 60 / 24 / 30) | 0
        },
        {
            name: `${name}Days`,
            label: `${label} (Days)`,
            type: 'number',
            value: async i => ((Date.now() - (await lifetimeInMs(i))) / 1000 / 60 / 60 / 24) | 0
        }
    ];
}

const BLOCK_CONDITIONS: ConditionVariable[] = [
    {
        name: 'user.staff',
        label: 'Staff',
        type: 'boolean',
        value: i => i.get(UserService).user?.staff ?? false
    },
    {
        name: 'user.email',
        label: 'Email',
        type: 'string',
        value: i => i.get(UserService).user?.email ?? false
    },
    {
        name: 'user.profile.wasFeatured',
        label: 'User Was Featured',
        type: 'boolean',
        value: i => !!i.get(UserService).user?.profile?.featured_at
    },
    {
        name: 'user.profile.city',
        label: 'City',
        type: 'boolean',
        value: i => !!i.get(UserService).user?.profile?.city
    },
    {
        name: 'user.audienceType',
        label: 'Audience Type',
        type: 'enum',
        value: i => i.get(UserService).audienceType,
        enumOptions: [
            { value: 'visitor', label: 'Visitor' },
            { value: 'registered', label: 'Registered' },
            { value: 'member', label: 'Member' },
            { value: 'expired member', label: 'Expired Member' }
        ]
    },
    ...lifetimeBlocks(`user.lifetime`, `Account Lifetime`, async i => {
        const userService = i.get(UserService);
        await userService.ready;

        const user = userService.user;
        if (!user)
            return 0;

        return new Date(user.created_at ?? Date.now()).getTime();
    }),
    ...lifetimeBlocks(`user.membership.lifetime`, `Membership Lifetime`, async i => {
        const userService = i.get(UserService);

        await userService.ready;

        const membership = userService.user?.membership;
        if (!membership)
            return 0;

        return new Date(membership.purchased_at ?? membership.created_at ?? Date.now()).getTime();
    })
];

@NgModule({
    imports: [
        ContentModule,
        FormsModule,
        CommonModule,
        TYTCommonModule,
        CommonUiModule,
        MatFormFieldModule,
        MatButtonModule,
        MatIconModule,
        MatSelectModule,
        MatInputModule,
        MatCheckboxModule,
        MatButtonToggleModule,
        MatTabsModule,
        MatDividerModule,
        MatMenuModule,
        MatTooltipModule,
        DragDropModule
    ]
})
export class CoreContentBlocksModule {
    private contentBlocks = inject(ContentBlocksService);
    constructor() {
        BLOCK_TYPES.forEach(type => this.contentBlocks.register(type));
        BLOCK_CONDITIONS.forEach(condition => this.contentBlocks.registerConditionVariable(condition));
    }
}
