import { ContentBlockType } from '@tytapp/content';
import { TabGroupBlock } from './tab-group-block';

export * from './tab-group-block';

export const TAB_GROUP_BLOCK_TYPE: ContentBlockType<TabGroupBlock> = {
    id: 'tab-group',
    label: 'Tab Group',
    icon: 'dashboard',
    isLayout: true,
    template: {
        headerPosition: 'above',
        initialTab: 0,
        tabs: [
            {
                id: '701d3749-96e0-4219-84a8-b17c3ad694d8',
                label: '',
                blocks: []
            }
        ]
    },
    preview: {
        tabs: [
            {
                id: '701d3749-96e0-4219-84a8-b17c3ad694d8',
                label: 'Tab 1',
                blocks: []
            },
            {
                id: '701d3749-96e0-4219-84a8-b17c3ad694d8',
                label: 'Tab 2',
                blocks: []
            }
        ]
    },
    deferredEditorComponent: () => import('./tab-group-block.edit').then(m => m.EditorComponent),
    deferredViewComponent: () => import('./tab-group-block.view').then(m => m.ViewComponent)
};
