import { ContentBlockType } from '@tytapp/content';
import { TeaserListBlock } from './teaser-list-block';

export * from './teaser-list-block';

export const TEASER_LIST_BLOCK_TYPE: ContentBlockType<TeaserListBlock> = {
    id: 'teaser-list',
    label: 'Teaser List',
    icon: 'feed',
    template: {
        items: []
    },
    preview: {
        items: [
            {
                image: `/assets/operation-hope/support-c2a-bg.png`,
                title: 'Lorem ipsum dolor sit amet',
                summary: 'The quick brown fox jumps over the lazy dog',
                date: '2024-04-20',
                tag: 'Money in Politics',
                url: 'https://google.com'
            },
            {
                image: `/assets/flag-backdrop.png`,
                title: 'Fusce vitae pretium nibh',
                date: '2023-10-01',
                tag: 'Social Justice',
                url: 'https://google.com'
            }
        ]
    },
    deferredEditorComponent: () => import('./teaser-list-block.edit').then(m => m.EditorComponent),
    deferredViewComponent: () => import('./teaser-list-block.view').then(m => m.ViewComponent)
};
