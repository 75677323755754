// webkit.messageHandlers.hostListener.postMessage(value);
// evaluate("receivePostMessage(value)");

declare let webkit;
type MessageHandler = (event: MessageEvent<any>) => void;

// iOS seems not to be able to provide a hostListener global object
// of the type we require for host API- so here's a small shim to make
// the two communication paths consistent (between iOS and Android).

if (typeof webkit !== 'undefined' && webkit?.messageHandlers?.hostListener) {
    let handlers: MessageHandler[] = [];

    window['hostListener'] = {
        postMessage: (message) => {
            webkit.messageHandlers.bridge.postMessage(message);
        },


        addEventListener: (type: 'message', handler: MessageHandler) => {
            handlers.push(handler);
        },

        removeEventListener: (type: 'message', handler: MessageHandler) => {
            let index = handlers.indexOf(handler);
            if (index >= 0)
                handlers.splice(index, 1);
        }
    };

    window['receivePostMessage'] = (data: string) => {
        handlers.forEach(handler => handler(<any>{ data }));
    };
}