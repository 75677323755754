import { NgModule } from "@angular/core";
import { MediaServiceRegistry } from "@tytapp/media-playback";
import { YouTubePlaybackService } from "./youtube-playback";

@NgModule({
    providers: [
        YouTubePlaybackService
    ]
})
export class TYTYouTubeModule {
    constructor(
        mediaServices: MediaServiceRegistry,
        mediaService: YouTubePlaybackService
    ) {
        mediaServices.register(mediaService);
    }
}