import { NgModule } from "@angular/core";
import { MediaServiceRegistry } from "@tytapp/media-playback";
import { JWPlaybackService } from "./jw-playback";

@NgModule({
    providers: [
        JWPlaybackService
    ]
})
export class TYTJWModule {
    constructor(
        mediaServices: MediaServiceRegistry,
        mediaService: JWPlaybackService
    ) {
        mediaServices.register(mediaService);
    }
}