import { ContentBlockType } from '@tytapp/content';
import { HowToWatchBlock } from './how-to-watch-block';

export * from './how-to-watch-block';

export const HOW_TO_WATCH_BLOCK_TYPE: ContentBlockType<HowToWatchBlock> = {
    id: 'how-to-watch',
    label: 'How To Watch C2A',
    icon: 'tv',
    editable: false,
    template: {
    },
    preview: {
    },
    deferredEditorComponent: () => import('./how-to-watch-block.edit').then(m => m.EditorComponent),
    deferredViewComponent: () => import('./how-to-watch-block.view').then(m => m.ViewComponent)
}
