import { ContentBlockType } from '@tytapp/content';
import { HeadingBlock } from './heading-block';

export * from './heading-block';

export const HEADING_BLOCK_TYPE: ContentBlockType<HeadingBlock> = {
    id: 'heading',
    label: 'Heading',
    icon: 'title',
    preview: {
        text: 'The quick brown fox jumps over the lazy dog'
    },
    previewSettings: {
        horizontalAlign: 'center',
        verticalAlign: 'center'
    },
    deferredEditorComponent: () => import('./heading-block.edit').then(m => m.EditorComponent),
    deferredViewComponent: () => import('./heading-block.view').then(m => m.ViewComponent)
};
