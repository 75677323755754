import { ContentBlockType } from '@tytapp/content';
import { YouTubeBlock } from './youtube-block';

export * from './youtube-block';

export const YOUTUBE_BLOCK_TYPE: ContentBlockType<YouTubeBlock> = {
    id: 'youtube',
    label: 'YouTube Video',
    icon: 'title',
    template: {

    },
    preview: {
        html_embed: `
            <iframe width="560" height="315" src="https://www.youtube.com/embed/mJ_HabP980M?si=jGdVhzmkKFfYsjkc"
                title="YouTube video player" frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen></iframe>
        `
    },
    previewSettings: {
        horizontalAlign: 'center',
        verticalAlign: 'center'
    },
    deferredEditorComponent: () => import('./youtube-block.edit').then(m => m.EditorComponent),
    deferredViewComponent: () => import('./youtube-block.view').then(m => m.ViewComponent)
};
