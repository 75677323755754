import { ContentBlockType } from '@tytapp/content';
import { EmbedBlock } from './embed-block';

export * from './embed-block';

export const EMBED_BLOCK_TYPE: ContentBlockType<EmbedBlock> = {
    id: 'embed',
    label: 'Embed (HTML)',
    icon: 'code',
    template: {
        html_embed: ''
    },
    preview: {
        html_embed: `
            The <em>quick</em> <strong>brown</strong> fox jumps
            <a href="https://google.com" target="_blank">over</a> the
            <strikethrough>lazy</strikethrough> dog
        `
    },
    previewSettings: {
        horizontalAlign: 'center',
        verticalAlign: 'center'
    },
    deferredEditorComponent: () => import('./embed-block.edit').then(m => m.EditorComponent),
    deferredViewComponent: () => import('./embed-block.view').then(m => m.ViewComponent)
};
