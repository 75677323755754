import { Injectable, inject } from '@angular/core';
import { ApiMiddlewareService } from './api-middleware.service';
import { NavigationStart, Router } from '@angular/router';
import { Observable, shareReplay } from 'rxjs';
import { LoggerService, PeriodicTaskService, buildQuery } from '@tytapp/common';
import { isClientSide } from '@tytapp/environment-utils';
import { environment } from '@tytapp/environment';

/**
 * Responsible for reusing API responses for duplicate API requests which occur within the bounds of a navigation
 * (both in CSR and SSR).
 * While reducing the number of duplicate calls is a laudable goal, in some cases it is not possible or impractical.
 * API responses are held indefinitely in SSR and for 30 seconds on CSR before being purged (and thus allowing a fresh
 * request to occur).
 */
@Injectable()
export class ApiReuse {
    private apiMiddleware = inject(ApiMiddlewareService);
    private router = inject(Router);
    private periodicTasks = inject(PeriodicTaskService);
    private logger = inject(LoggerService);

    private expiryTimer;
    private totalServed = 0;
    private totalReused = 0;
    private requests = new Map<string, Observable<any>>();

    private blacklist: RegExp[] = [
        /^\/users\/current\/notifications/,
        /^\/users\/current\??$/,
        /^\/live\/streams\/indicator/
    ];

    addToBlacklist(path: RegExp) {
        this.blacklist.push(path);
    }

    private waitForExpiry() {
        if (isClientSide()) {
            this.periodicTasks.cancel(this.expiryTimer);
            this.expiryTimer = this.periodicTasks.scheduleOnce(30_000, () => this.expire());
        }
    }

    private expire() {
        this.requests.clear();
        if (environment.showDevTools && this.totalServed > 0 && this.totalReused > 0) {
            this.logger.info(`[ApiReuse] Reused ${this.totalReused} / ${this.totalServed} API requests (${this.totalReused / this.totalServed * 100 | 0}%)`);
        }
        this.totalServed = 0;
        this.totalReused = 0;
    }

    install() {
        this.router.events.subscribe(ev => {
            if (ev instanceof NavigationStart)
                this.expire();

            this.waitForExpiry();
        });

        this.waitForExpiry();
        this.apiMiddleware.install('reuse', (request, next) => {
            if (request.method !== 'GET')
                return next(request);

            let fullPath = `${request.path}?${buildQuery(request.query)}`;
            let isBlacklisted = this.blacklist.some(x => x.test(fullPath));

            if (isBlacklisted)
                return next(request);

            if (!this.requests.has(fullPath)) {
                this.requests.set(fullPath, next(request).pipe(shareReplay(1)));
            } else {
                this.logger.debug(`[ApiReuse] Reusing API call ${fullPath}`);
                this.totalReused += 1;

                if (this.totalReused > 10_000) {
                    this.logger.error(`[ApiReuse] Detected invalid state: over 10k reused API calls. This is almost certainly a bug.`);
                    debugger;
                }
            }

            this.totalServed += 1;
            return this.requests.get(fullPath);
        });
    }
}