import { RouteReuseStrategy, ActivatedRouteSnapshot, DetachedRouteHandle } from "@angular/router";
import { Injectable } from "@angular/core";

@Injectable()
export class CustomRouteReuseStrategy extends RouteReuseStrategy {
    public shouldDetach(route: ActivatedRouteSnapshot): boolean { return false; }
    public store(route: ActivatedRouteSnapshot, detachedTree: DetachedRouteHandle): void { }
    public shouldAttach(route: ActivatedRouteSnapshot): boolean { return false; }
    public retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle { return null; }
    public shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
        if (typeof curr.data.reuse === 'string' && typeof future.data.reuse === 'string') {
            return curr.data.reuse === future.data.reuse;
        }

        // Allow opting out of route reuse even when we normally would.
        // SlugComponent uses this for instance.
        if (future.routeConfig === curr.routeConfig && curr.data.reuse === false)
            return false;

        return (future.routeConfig === curr.routeConfig)
            || (curr.data.reuse && future.component === curr.component);
    }
}