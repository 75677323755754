import { ContentBlockType } from '@tytapp/content';
import { NewsletterSignupBlock } from './newsletter-signup-block';

export * from './newsletter-signup-block';

export const NEWSLETTER_SIGNUP_BLOCK_TYPE: ContentBlockType<NewsletterSignupBlock> = {
    id: 'newsletter-signup',
    label: 'Newsletter Signup',
    icon: 'email',
    editable: false,
    template: {
    },
    preview: {
    },
    deferredEditorComponent: () => import('./newsletter-signup-block.edit').then(m => m.EditorComponent),
    deferredViewComponent: () => import('./newsletter-signup-block.view').then(m => m.ViewComponent)
}
